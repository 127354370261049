<template>
  <div class="container has-text-centered">
    <div class="columns">
      <div class="column is-offset-4 is-4 card">
        <h2
          class="title is-2 is-dark"
          style="color: #000000; padding: 0.5em 1em 0"
        >
          ເຂົ້າສູ່ລະບົບ
        </h2>
        <form>
          <b-field>
            <b-input placeholder="USER ID" v-model="username"> </b-input>
          </b-field>

          <b-field>
            <b-input
              v-model="password"
              type="password"
              placeholder="ລະຫັດຜ່ານ"
              password-reveal
              @keyup.native.enter="login()"
            >
            </b-input>
          </b-field>
          <div class="columns" style="margin-top: 20px">
            <div class="column">
              <b-button
                class="is-primary is-pulled-right"
                @click="login()"
                icon-left="key"
              >
                ເຂົ້າສູ່ລະບົບ
              </b-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>
<script>
import Axios from "axios";
import Swal from "sweetalert2";
import { get } from "vuex-pathify";

export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null,
      isLoading: false,
    };
  },
  computed: {
    savedusername: get("username"),
  },
  mounted() {},
  methods: {
    login() {
      var params = {
        username: this.username,
        password: this.sha512(this.password),
      };
      this.isLoading = true;
      Axios.post("/login", params)
        .then((r) => {
          this.isLoading = false;
          this.$store.set("user", r.user);
          this.$store.set("token", r.token);
          this.$store.set("provinces", r.provinces);
          this.$store.set("districts", r.districts);
          this.$store.set("branches", r.branches);
          this.$store.set("parceltypes", r.parceltypes);
          this.$store.set("shipmentStatuses", r.shipmentStatuses);
          this.$store.set("banks", r.banks);
          this.$store.set("currencies", r.currencies);
          this.$store.set("exchangerate", r.exchangerate);
          this.$store.set("paymentmethods", r.paymentmethods);
          this.$router.replace({ path: "/home" });
        })
        .catch((e) => {
          this.isLoading = false;
          console.error(e);
          Swal.fire(e.message, "", "error");
        });
    },
  },
};
</script>
